import { createColumnHelper } from '@tanstack/react-table';
// import { Link } from 'react-router-dom';
// import { cn } from '../../helpers/utils';
import Header from './components/Header';
import MfrPartCell from './components/MfrPartCell';
import MiniBtnAddToCart from '../../components/layout/Buttons/MiniBtn-Add-To-Cart';

const columnHelper = createColumnHelper();

export const columns = [
  columnHelper.accessor('name', {
    header: <Header name="Manufacturer">Mfr Part #</Header>,
    cell: (info) => <MfrPartCell product={info.row.original} />,
  }),
  columnHelper.accessor('CTYEA_Part_Number', {
    header: <Header name="CTYEA_Part_Number">Part Number</Header>,
    cell: (info) => <p className="text-sm font-bold">{info.getValue()}</p>,
  }),
  columnHelper.accessor('price', {
    header: <Header name="price">Price</Header>,
    cell: (info) => (
      <p className="text-sm font-bold">${info.getValue().toFixed(2)}</p>
    ),
  }),
  columnHelper.accessor('stock', {
    header: <Header name="stock">Stock</Header>,
    cell: (info) => {
      if (
        info.getValue() === undefined ||
        info.getValue() === null ||
        info.getValue() === '' ||
        +info.getValue() === 0
      ) {
        return (
          <p className="flex flex-col text-sm font-bold">
            <div className="flex flex-col gap-2">
              <span className="text-xs text-red-500">Out of Stock</span>
              {/* <Link
                to={'/get_quote'}
                className="bg-primary w-fit rounded-full px-2 py-1 text-center text-xs text-white"
              >
                Get Quote
              </Link> */}
            </div>
          </p>
        );
      }
      return (
        <p className="flex flex-col text-sm font-bold">
          <span>{info.getValue()}</span>
        </p>
      );
    },
  }),
  columnHelper.accessor('Weight', {
    header: <Header name="Weight">Weight</Header>,
    cell: (info) => <p className="text-sm font-bold">{info.getValue()}</p>,
  }),
  columnHelper.accessor('Weight', {
    header: <p className="w-full px-4 text-center">Add To Cart</p>,
    cell: (info) => {
      console.log(info.row.original);
      return <MiniBtnAddToCart {...info.row.original} />;
    },
  }),
];
