export const PREFIXES = {
  yocto: { name: "yocto", label: "y", value: Math.pow(10, -24) },
  zepto: { name: "zepto", label: "z", value: Math.pow(10, -21) },
  atto: { name: "atto", label: "a", value: Math.pow(10, -18) },
  femto: { name: "femto", label: "f", value: Math.pow(10, -15) },
  pico: { name: "pico", label: "p", value: Math.pow(10, -12) },
  nano: { name: "nano", label: "n", value: Math.pow(10, -9) },
  micro: { name: "micro", label: "µ", value: Math.pow(10, -6) },
  milli: { name: "milli", label: "m", value: Math.pow(10, -3) },
  centi: { name: "centi", label: "c", value: Math.pow(10, -2) },
  deci: { name: "deci", label: "d", value: Math.pow(10, -1) },
  deca: { name: "deca", label: "da", value: Math.pow(10, 1) },
  hecto: { name: "hecto", label: "h", value: Math.pow(10, 2) },
  kilo: { name: "kilo", label: "k", value: Math.pow(10, 3) },
  mega: { name: "mega", label: "M", value: Math.pow(10, 6) },
  giga: { name: "giga", label: "G", value: Math.pow(10, 9) },
  tera: { name: "tera", label: "T", value: Math.pow(10, 12) },
  peta: { name: "peta", label: "P", value: Math.pow(10, 15) },
  exa: { name: "exa", label: "E", value: Math.pow(10, 18) },
  zetta: { name: "zetta", label: "Z", value: Math.pow(10, 21) },
  yotta: { name: "yotta", label: "Y", value: Math.pow(10, 24) },
  mil: { name: "mil", label: "mil", value: 0.0000254 },
  inch: { name: "inch", label: "in", value: 0.0254 },
};

export const UNITS = {
  ohm: "Ω",
  meter: "m",
  second: "s",
  ampere: "A",
  kelvin: "K",
  hertz: "Hz",
  newton: "N",
  pascal: "Pa",
  joule: "J",
  watt: "W",
  coulomb: "C",
  volt: "V",
  farad: "F",
  siemens: "S",
  weber: "Wb",
  tesla: "T",
  voltampere: "VA",
  voltampereReactive: "VAR",
  ampereHour: "Ah",
  henry: "H",
};
